import React, {useEffect, useState, useContext} from 'react';
import styles from './navgoal.module.css';

import { NavLink } from "react-router-dom";

import { SelectedGoalContext } from "../../../context/selectedgoal";

import ImageGoal from '../../1atoms/ImageGoal/ImageGoal';
import { displayDate } from '../../../hooks/useDate';

export default function NavGoal({types, goal, active, popover}) {

  // Selected Goal
  const selectedgoal = useContext(SelectedGoalContext);
  const [selected, setSelect] = useState(false)

  useEffect(() => {
    if (selectedgoal) {
      if (selectedgoal.data.id === goal.id) {
        setSelect(true)
      } else {
        setSelect(false)
      }
    }
  }, [selectedgoal])


// Type of Nav Item
let type = ' ';
switch (types) {
    case 'preview':
      type += styles.preview;
        break;
    case 'banner':
      type += styles.banner;
        break;
    case 'nav':
      type += styles.nav;
        break;
    default: type = styles.nav;
}


// Details Information
// Details is set with useState because it crashed when a goal was deleted
const [details, setDetails] = useState('')

useEffect(() => {
  
    if (goal) {
      if (goal.end!==undefined || goal.start!==undefined || goal.end!=='' || goal.start!=='') {
  
        let displayEnd = displayDate(new Date(goal.end));
        let displayStart = displayDate(new Date(goal.start));
        
        setDetails(
        <div className={styles.details}>
          <h3>{goal.cause}</h3>
          <p>{`${displayStart} - ${displayEnd}`} <br/>
          {/* {goal.orgName} */}
          </p>
        </div>
        )
      }
    } else {
      setDetails(
      <div className={styles.details}>
        <h3>No Name</h3>
        <p>No Dates Selected 
        {/* <br/>No Creator Name */}
        </p>
      </div>
      )
    }
}, [goal]);


// Link Information 
const [link, setLink] = useState('')

useEffect(() => {

  setLink(''); // Reset Link

  if (goal) {
    setLink(`/${active ? 'active' : 'join'}/${goal.id}`)
  }

}, [active, goal])

  if (types==='nav') {
    return (
      
      <NavLink
        reloadDocument={popover && true}
        to={link}
        replace={true}
        style={{opacity: selected ? "1" : ""}}
        className={`${styles.container} ${type}`}
      >
        <ImageGoal image={goal ? goal.icon : ""} />
        {details}
      </NavLink>
      
    );
  } else {
    return (
      <div className={`${styles.container} ${type}`}>
          <ImageGoal image={goal ? goal.icon : ''}/>
          {details}
      </div>
    )
  }
}
