import React from 'react'
import styles from './iconloading.module.css';

export default function IconLoading(props) {
  let size = ' ';
switch (props.size) {
    case 'large':
        size += styles.large;
        break;
    case 'medium':
        size += styles.medium;
        break;
    case 'small':
        size += styles.small;
        break;
    default: size = styles.small;
}

  return (
    <>
    
    <div className={`${styles.circle} ${size}`}></div>
    
    </>
    
  )
}
