import React from 'react';
import styles from './button.module.css';
import IconLoading from '../../1atoms/IconLoading/IconLoading';


export default function Button(props) {


let size = ' ';
switch (props.size) {
    case 'large':
        size += styles.large;
        break;
    case 'medium':
        size += styles.medium;
        break;
    case 'small':
        size += styles.small;
        break;
    case 'tiny':
        size += styles.tiny;
        break;
    default: size = styles.medium;
}

let type = ' ';
switch (props.type) {
    case 'upload':
    type += styles.primary;
        break;
    case 'popover':
    type += styles.icon;
        break;
    case 'primary':
    type += styles.primary;
        break;
    case 'create':
    type += styles.create;
        break;
    case 'cancel':
    type += styles.cancel;
        break;
    case 'test':
        type += styles.test;
            break;
    case 'icon':
        type += styles.icon;
        break;
    case 'banner':
        type += `${styles.icon} ${styles.banner}`; // Used on the goal image background to overlay the icon on the image
        break;
    default: type = '';
}

let state = ' ';
switch (props.state) {
    case 'loading':
        state += styles.loading;
        break;
    case 'unavailable':
        state += styles.unavailable;
        break;
    default: state = '';
  }


  if (props.type==='upload'){
    return (
        <form className={styles.formContainer}>
            {props.state==="loading" && <div className={styles.loadingOverlay}><IconLoading/></div>}
            <input id="image_uploads" accept="image/png, image/jpeg, image/jpg" className={`${styles.button}${state}${type}`} type='file' onChange={props.click}/>
            
        </form>
    )
  } else if (props.type==='popover'){
    return (
        <button className={`${styles.button} ${type} ${styles.small}`} popovertarget={props.click} popovertargetaction="toggle" >
            {props.children}
        </button>
    )
  }
  
  else {
    return (
        <button className={`${styles.button} ${state} ${type} ${size}`} onClick={props.click} >
            {props.state==="loading" ? <span className={styles.icon}><IconLoading/></span> : props.children}  
        </button>
    )
  }

}
