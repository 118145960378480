import React from 'react'
import styles from './inputdetails.module.css';

export default function InputDetails(props) {

  let position = ' ';
switch (props.position) {
    case 'top':
      position += styles.top;
        break;
    case 'bottom':
      position += styles.bottom;
        break;
    default: position = styles.top;
}


  return (
    <p className={`${styles.details} ${position}`}>{props.text}</p>
  )
}
