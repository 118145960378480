import React, {useContext, useState, useEffect} from 'react';
import styles from './navmain.module.css';

import { Link } from "react-router-dom";
import { CompareDateLocal } from '../../../hooks/useDate';

import { GoalsContext } from "../../../context/goals";
import { UserContext } from "../../../context/user";

import NavGoal from '../../2molecules/NavGoal/NavGoal';
import Button from '../../2molecules/Button/Button';
import Icons from '../../1atoms/Icons/Icons';
import StateEmpty from '../../2molecules/StateEmpty/StateEmpty';

export default function NavMain({popover}) {

  const goals = useContext(GoalsContext);
  const user1 = useContext(UserContext);

  const [actives, setActive] = useState([]);
  
  useEffect(() => {
    setActive([]);
    if (goals) {
      if (goals.data === 'Empty State') {
        setActive(array => [...array, <StateEmpty key='1' type='nav' >Create or select a goal to get started</StateEmpty>])
      } else {
        goals.data.map((goal, i) => {
          if (user1.data.type === 'admin') {
            if (goal.demo !== true) {
              if (goal.completedusers && goal.completedusers.includes(user1.data.uid)) {
                // console.log('completed');
              } else {
                if (goal.currentusers.includes(user1.data.uid)) {
                  setActive(array => [...array, <NavGoal key={i} popover={popover} types="nav" active={true} goal={goal} />]);
                }
              }
            }
          } else {
            if (goal.completedusers && goal.completedusers.includes(user1.data.uid)) {
              
            } else {
              if (goal.currentusers.includes(user1.data.uid)) {
                setActive(array => [...array, <NavGoal key={i} popover={popover} types="nav" active={true} goal={goal} />]);
              }
            }
          }
        })
      }
    }
    
  }, [goals])


  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions([]);
    if (goals) {
      if (goals.data === 'Empty State') {
        setSuggestions(array => [...array, <StateEmpty key='1' type='nav' >No more goals to suggest at this time</StateEmpty>])
      } else {
        goals.data.map((goal, i) => {
          let dateLeftAmount = CompareDateLocal(goal.end);

          if (goal.demo !== true) {
            if (!goal.currentusers.includes(user1.data.uid) & dateLeftAmount < 1) {
              if (goal.visibility === 'private') {
                if (goal.orgID===user1.data.uid) {
                  setSuggestions(array => [...array, <NavGoal key={i} types="nav" goal={goal} />]);
                }
              } else {
                setSuggestions(array => [...array, <NavGoal key={i} types="nav" goal={goal} />]);
              }
            }
          }

        })
      }
    }
    
  }, [goals])

  return (
    <div className={styles.container}>
      <h2>
        Goals
        {(user1.data.type==='admin' || user1.data.type==='org') && 
          <Link to="/create">
            <Button size="tiny" type="icon">
              <Icons size="tiny" icon="plus" />
            </Button>
          </Link>
        }
      </h2>


      <div className={styles.section}>

     { 
      actives.length < 1 ? 
        <StateEmpty key='1' type='nav' >
          {user1.data.type==='admin' || user1.data.type==='org' ? 'Create or select a goal to get started' : 'Select a goal to get started'}
        </StateEmpty> :
      actives.map((active) => { return active }) 
      }
     
      </div>

      <h2 className={styles.space}>Suggested</h2>

      <div className={styles.section}>
      { 
        suggestions.length < 1 ? <StateEmpty key='1' type='nav' >No more goals to suggest at this time</StateEmpty> :
        suggestions.map((suggest) => { return suggest }) 
      }
      </div>
    </div>
  );
}
