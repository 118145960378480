import React from "react";
import styles from './icons.module.css';

export default function Icons(props) {

  let size = ' ';
switch (props.size) {
    case 'tiny':
      size += styles.tiny;
      break;
    case 'small':
        size += styles.small;
        break;
    case 'medium':
        size += styles.medium;
        break;
    case 'large':
        size += styles.large;
        break;
    case 'display':
        size += styles.display;
        break;
    default: size = '';
} 

  return (
      <svg className={size}>
        <use xlinkHref={`/icons/solid.svg#${props.icon}`}></use>
      </svg>
  );
}
