import React from 'react';
import noimage from '../../../images/image-no-object.svg';
import styles from './imagegoal.module.css';

export default function ImageGoal({image}) {

  let src = image ? image : noimage;

  return (
      <div className={`${styles.container} ${!image && styles.noimage}`}>
          <img src={src} />
      </div>
  )
}
