import React from "react";
import styles from "./marketingpage.module.css";

import { Link } from "react-router-dom";

import display from "./images/display-main-goal.png";
import peacock from "./images/great-goal-peacock.svg";
import icon1 from "./images/icon-user.svg";
import icon2 from "./images/icon-groups.svg";
import icon3 from "./images/icon-community.svg";

import MarketingTemplate from "../../4templates/MarketingTemplate/MarketingTemplate";
import MarketingMainBanner from "./MarketingMainBanner";
import MarketingMainSlides from "./MarketingMainSlides";
import MarketingMainRibbon from "./MarketingMainRibbon";
import Button from "../../2molecules/Button/Button";

export default function MarketingMainPage() {
  return (
    <MarketingTemplate
      type="main"
      top={
        <>
          <MarketingMainBanner />
          <MarketingMainSlides />
        </>
      }
      bottom={<MarketingMainRibbon />}
    >
      <section className={styles.section}>
        <article id="tracking" className={styles.container}>
          <div className={styles.leftPanel}>
            <img
              className={styles.display}
              src={display}
              alt="Desktop computer showing the main page of the social goal tracking application"
            />
          </div>

          <div className={styles.rightPanel}>
            <div className={styles.icons}>
              <img src={icon1} alt="Individual Users" />
              <img src={icon2} alt="Groups of Users" />
              <img src={icon3} alt="Community Support" />
            </div>
            <h2>Social Goal Tracking</h2>
            <h3>For People</h3>
            <p>
              <strong>Yes of course your goals are important</strong> but the
              friends and community it benefits will have a monumental effect.
              They will help you while you are helping them because that’s what
              it means to be part of this world. Someone else out there is glad
              you have this goal and we are here to help you connect.
            </p>
            <Link to="/signup">
              <Button type="primary">TRY TODAY</Button>
            </Link>
          </div>
        </article>
      </section>

      <section id="fundraising" className={styles.section}>
        <article className={styles.container}>
          <div className={styles.onlyPanel}>
            <img
              className={styles.charity}
              src={peacock}
              alt="Fundraising Great Goal Icon"
            />
            <h2>Fundraising?</h2>
            <h3>Community Involement and Exposer</h3>
            <p>
              Month-long challenges where groups and clubs get together for your
              cause to improve themselves at their skill level and pace.
            </p>
            <Link to="/signup">
              <Button type="primary">LEARN MORE</Button>
            </Link>
          </div>
        </article>
      </section>
    </MarketingTemplate>
  );
}
