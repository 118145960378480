import { useState, useEffect } from 'react';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

export function useAuthStatus() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [checkingStatus, setCheckingStatus] = useState(true);

    useEffect(() => {

        const auth = getAuth();
        
        onAuthStateChanged(auth, (user)=> {
            // console.log(user);
            if (user) {
                setLoggedIn(true)
            }

            setCheckingStatus(false);
        })

    }, []);

  return {loggedIn, checkingStatus}

}