import React from 'react';
import styles from './marketingtemplate.module.css';
import HeaderMain from '../../3organisms/HeaderMain/HeaderMain';

export default function MarketingTemplate(props) {
  return (
    <div className={styles.section}>
      <HeaderMain type="marketing"/>
      {props.top && props.top}
      <main className={`${props.banner && styles.bannerTopMargin} ${styles.container}`}>
        {props.children} 
      </main>
      {props.bottom && props.bottom}
      <HeaderMain type="footer"/>
      
    </div>
  );
}
