import React, {useState} from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useForm } from 'react-hook-form';

import Button from '../../2molecules/Button/Button';
import StartTemplate from '../../4templates/StartTemplate/StartTemplate';
import Input from '../../2molecules/Input/Input';

// Firebase
import "../../../firebase/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function PasswordResetPage() {

    document.title = 'Password Reset';

    const { register, handleSubmit: handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState('');
    const navigate = useNavigate();

    const onSubmit = (async (values) => {
      setLoading('loading');
      try {
        
        const auth = getAuth();
        await sendPasswordResetEmail(auth, values.email); 
        console.log('Email was sent');
        navigate('../signin'); 
  
      } catch (error) {
        console.log(error);
        console.log('Your email was not found');
        
      }
      setLoading('');
  
    })

  return (
    

    <StartTemplate title={'Password Reset'} >
    <form autoComplete={"off"}>
    <p>Enter your email address to reset your password.</p>
        <Input 
            label="Email" 
            type="email" 
            name="email" 
            register={register} 
            required 
            error={errors.email}
            message="Must have a value"
        />
        

        <Button type={'primary'} click={handleSubmit(onSubmit)} state={loading}>Reset</Button>
        <br/>
        <Link style={{textAlign: 'center'}} to="/signin">Sign In</Link>

      </form>
    </StartTemplate>

  )
}
