import React from 'react';
import styles from './marketingpage.module.css';

import banner1 from './images/marketing-banner-draft.png';


export default function MarketingMainBanner() {
  return (
    <>
        <section className={styles.sectionRibbon}>
          <article className={styles.containerRibbon}>
            <h3>To go fast go alone, to go far go together, <br/><strong>to go for the future go for a cause.</strong></h3>
          </article>
        </section>
    </>
      
  )
}
