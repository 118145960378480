import React, {Suspense} from 'react';
import {Outlet, Navigate} from 'react-router-dom';

import {useAuthStatus} from '../hooks/useAuthStatus';

export default function PirvateRoute() {

    const {loggedIn, checkingStatus} = useAuthStatus();

    if (checkingStatus) {
        return <p>loading...</p>;
    }

  return loggedIn ? <Suspense><Outlet/></Suspense>: <Navigate to='/'/>
}