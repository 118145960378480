import React from 'react';
import styles from './stateempty.module.css';
import Icons from '../../1atoms/Icons/Icons';
import TypeDetails from '../../1atoms/TypeDetails/TypeDetails';

export default function StateEmpty(props) {

if (props.type === 'fullpage' || props.type === 'section') {
  return (
    <div className={`${styles.container} ${styles.medium} ${props.type === 'section' && styles.transparent}`}>
        {props.icon && <div className={styles.iconContainer}><Icons icon={props.icon} size='medium' /></div>}
        <p className={styles.stateempty}>
            {props.children}
        </p>
    </div>

  )} else if (props.type === 'nav') {
    return (
        <div className={`${styles.container} ${styles.nav}`}>
            {props.icon && <Icons icon={props.icon} size='small' />}
            <TypeDetails text={props.children} />
        </div>
    )
  } else {
   return (     
        <div className={`${styles.container} ${styles.medium}`}>
            {props.icon && <div className={styles.iconContainer}><Icons icon={props.icon} size='medium' /></div>}
            <p className={styles.stateempty}>
                {props.children}
            </p>
        </div>
   )
  }
}
