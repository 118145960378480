
// Date Functions In Use

// Store Dates in Firebase ISO 8601 String 2023-09-27T00:00:00.000Z
export const storeDate = (value) => {
    let df = new Date(value).toISOString(); 
    return df;
}

// Used to Display Dates in the UI
export const displayDate = (value) => {

    let date = new Date(value);    
    let dd = date.getUTCDate();
    let dm = date.getUTCMonth() + 1; // Return Value is 0 indexed
    let df = `${dm}/${dd}`;
    return df;

}

// Used to Display Dates with time value in ISO 8601 String 2023-09-27T00:00:00.000Z
export const displayAtLocal = (value) => {

    let date = new Date(value);    
    let ld= date.toLocaleDateString('en-US', {
        month: 'numeric',
        day: 'numeric'
      });
    return ld;

}

// Used to Popluate Date Fields
export const progressDate = (value) => {
  
    let date = new Date(value);
    let dd = ('0' + date.getUTCDate()).slice(-2); //Adds a 0 to number 1 - 9
    let dm = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Return Value is 0 indexed why the + 1
    let dy = date.getUTCFullYear();
    let df = `${dy}-${dm}-${dd}`;
    return df;
}

// Compare to dates that are that are not the local date
export const compareDateUTC = (value1, value2) => {
       
    // Removes hours from the math to keep from any time zone issues
    let date1 = new Date(value1);
    let month1 = date1.getUTCMonth() + 1;
    let day1 = date1.getUTCDate();
    let year1 = date1.getUTCFullYear();
    let cd1 = new Date(`${month1}/${day1}/${year1}`);

    let date2 = new Date(value2);
    let month2 = date2.getUTCMonth() + 1;
    let day2 = date2.getUTCDate();
    let year2 = date2.getUTCFullYear();
    let cd2 = new Date(`${month2}/${day2}/${year2}`);

    let cd = Math.floor((cd2 - cd1) / (1000 * 60 * 60 * 24) + 1);

    return cd;

}

// Compares a date to the local date
export function CompareDateLocal(value2) {


    // const [compareLocal, setSompareLocal] = useState('');

    let date1 = new Date();

    // useEffect(() => {
        // Today's Date - Removes hours from the math to keep from any time zone issues
        
        let month1 = date1.getMonth() + 1;
        let day1 = date1.getDate();
        let year1 = date1.getFullYear();
        let cd1 = new Date(`${month1}/${day1}/${year1}`);

        // Later Date - Removes hours from the math to keep from any time zone issues
        let date2 = new Date(value2);
        let month2 = date2.getUTCMonth() + 1;
        let day2 = date2.getUTCDate();
        let year2 = date2.getUTCFullYear();
        let cd2 = new Date(`${month2}/${day2}/${year2}`);

        let cd = Math.floor((cd1 - cd2) / (1000 * 60 * 60 * 24) + 1);
        // setSompareLocal(cd)
 
    // }, [date1])
    
    

    return cd;

}

// Backup Date Functions




// export const progressDate = (value) => {
  
//     let date = new Date(value);
//     let dd = ('0' + date.getUTCDate()).slice(-2); //Adds a 0 to number 1 - 9
//     let dm = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Return Value is 0 indexed why the + 1
//     let dy = date.getUTCFullYear();
//     // let df = `${dm}/${dd}/${dy}`;
//     let df = `${dy}-${dm}-${dd}`;
 
//     return df;

// }


// Used Currently In Daily Markers

// export const compareDate = (value) => {
  
//     let date = new Date(value);
//     let dd = ('0' + date.getUTCDate()).slice(-2); //Adds a 0 to number 1 - 9
//     let dm = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Return Value is 0 indexed why the + 1
//     let dy = date.getUTCFullYear();
//     let df = `${dy}${dm}${dd}`;
//     let finaldate = Number(df);

//    return finaldate;

// }

// Dev Date
// export const devDate = (value) => {

//     let date = value.toDate(); 
//     let dd = date.getUTCDate();
//     let dm = date.getUTCMonth() + 1; // Return Value is 0 indexed
//     let df = `${dm}/${dd}`;

//     return df;

// }


// export const compareLocal = () => {
    
//     let date = new Date();

//     let dd = ('0' + date.getDate()).slice(-2);
//     let dm = ('0' + (date.getMonth() + 1)).slice(-2); // Return Value is 0 indexed
//     let dy = date.getFullYear();
//     let df = `${dy}${dm}${dd}`;
//     let finaldate = Number(df);
//    return finaldate;

// }

// Not Used

// export const storeDate = (value) => {

//     let dateformat = new Date(value).toISOString(); 
//     return dateformat;

// }

