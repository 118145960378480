import { useState, createContext, useEffect} from "react";

// Firebase
import { db } from "../firebase/firebase";
import { doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

export const SystemContext = createContext();

export default function UserProvider( {children} ) {
  const [data, setSystemData] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);

  const auth = getAuth();

  useEffect(() => {

  if (auth) {
    const fetchData = async () => {
      try {
        const systemPath = doc(db, `settings/system`);
        const unsub = onSnapshot(systemPath, (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            setSystemData(docData);  
            setIsLoaded(true);
            
          }
        });
        return unsub;
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchData();
  }
  

  }, [auth]);

 

  return <SystemContext.Provider value={{data}}>{isLoaded && children}</SystemContext.Provider>;
}

