import React, {useState, useContext} from 'react';

import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { SystemContext } from "../../../context/system"

// Firebase
import { db } from "../../../firebase/firebase";
import { getAuth, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc} from 'firebase/firestore';

// Components
import Button from '../../2molecules/Button/Button';
import StartTemplate from '../../4templates/StartTemplate/StartTemplate';
import Input from '../../2molecules/Input/Input';
import StateEmpty from '../../2molecules/StateEmpty/StateEmpty';
import FormMarketingEarlyAccess from '../../3organisms/Forms/FormMarketingEarlyAcces/FormMarketingEarlyAccess';
import TypeDetails from '../../1atoms/TypeDetails/TypeDetails';

export default function SignUpPage({status}) {

  const system = useContext(SystemContext);
  let accessStatus = system.data.earlyAccess;

  let pageTitle = ' ';
  switch (accessStatus) {
    case 'closed':
        pageTitle = 'Early Access Closed';
        break;
    case 'request':
        pageTitle = 'Request Early Access'
        break;
    case 'open':
        pageTitle = 'Sign Up'
        break;
    default: pageTitle = 'Sign Up';
  } 

  document.title = pageTitle;

  const { register, handleSubmit, formState: {errors}, reset} = useForm();
  const [loading, setLoading] = useState('');
  const navigate = useNavigate();

  const onSubmit = (async (values) => {

    setLoading('loading');

    try {
      const auth = getAuth();
      const userCrednetial = createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = await userCrednetial;
      updateProfile(auth.currentUser, {displayName: values.first + ' ' + values.last})

      const userPath = new doc(db, `users/${auth.currentUser.uid}`);
      const formSignUpData = {...values};
      formSignUpData.color = '--color-gray-5';
      formSignUpData.uid = auth.currentUser.uid;
      formSignUpData.status = 'new';
      formSignUpData.type = 'user';
      formSignUpData.activegoals = Number(0);
      delete formSignUpData.password;
      await setDoc(userPath, formSignUpData);

      if (user) {
        navigate('/main');
      }

    } catch (error) {
      console.log(error);
    }

    setLoading('');  
    reset();

  })

  if (accessStatus === 'closed') {
    return (
      <StartTemplate title={'Currently Closed'} >
        <StateEmpty icon='lock' type='fullpage'>
          Sorry, early access is currently closed.
        </StateEmpty>

        <p style={{textAlign: 'center'}}>
        <Link to="../marketing">Back To Marketing Page</Link></p>

      </StartTemplate>
    )
  } else if (accessStatus === 'request') {

    return (
      <StartTemplate title={'Request Early Access'} >
        <TypeDetails align="center" text="Sign up below to be notified when early access opens." />
        <br/>
        <FormMarketingEarlyAccess/>

        <p style={{textAlign: 'center'}}>
        <Link to="../marketing">Back To Marketing Page</Link></p>

      </StartTemplate>
    )

  } else if (accessStatus === 'open') {

  return (

          <StartTemplate title={'Get Started'} >

              <form autoComplete={"off"}>
                <Input 
                  label="First Name" 
                  type="text" 
                  name="first" 
                  register={register} 
                  required 
                  error={errors.firstName}
                  message="Must have a value"
                />
                <Input 
                  label="Last Name" 
                  type="text" 
                  name="last" 
                  register={register} 
                  required 
                  error={errors.lastName}
                  message="Must have a value"
                />

                <Input 
                  label="Email" 
                  type="email" 
                  name="email" 
                  register={register} 
                  required 
                  error={errors.email}
                  message="Must have a value"
                />

                <Input 
                  label="Password" 
                  type="text" 
                  name="password" 
                  register={register} 
                  required 
                  error={errors.password}
                  message="Must have a value"
                />
                <Button type={'primary'} state={loading} click={handleSubmit(onSubmit)}>Sign Up</Button>
                  
              </form>
              <div className={'panelForm'}>
    
                <br/>
                <p style={{textAlign: 'center'}}>Already have an account? <Link to="../signin">Sign In</Link></p>
              </div>
          </StartTemplate>

  )
  }
}
