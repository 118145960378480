import React from "react";
import styles from "./marketingpage.module.css";
import { Link } from "react-router-dom";

import background from "./images/banner-background.png";
import mobile from "./images/banner-background-mobile.png";

import Icons from "../../1atoms/Icons/Icons";

export default function MarketingMainBanner() {
  return (
    <>
      <section className={`${styles.desktopBanner} ${styles.sectionBanner}`}>
        <article
          className={styles.containerBanner}
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className={styles.leftBanner}></div>
          <div className={styles.rightBanner}>
            <h1>
              Accomplish
              <br /> Anything
            </h1>
            <p>Start your goal today!</p>

            <Link to="/signup">
              <button>
                GET STARTED <Icons icon="arrow-right" />
              </button>
            </Link>
          </div>
        </article>
      </section>

      <section className={`${styles.mobileBanner} ${styles.sectionBanner}`}>
        <article
          className={styles.containerBanner}
          style={{ backgroundImage: `url(${mobile})` }}
        >
          <h1>
            Achieve goals
            <br /> with friends
          </h1>
          <p>Start your goal today!</p>
          <Link to="/signup">
            <button>
              GET STARTED <Icons icon="arrow-right" />
            </button>
          </Link>
        </article>
      </section>
    </>
  );
}
