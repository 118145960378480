import React from 'react'
import styles from './input.module.css';

import InputError from '../../1atoms/InputError/InputError';
import InputDetails from '../../1atoms/InputDetails/InputDetails';
import Icons from '../../1atoms/Icons/Icons';

export default function Input({ label, type, name, register, required, error, message, placeholder, details, change, button, hubspot }) {

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

 if (button) {
  // Input and Button next to each other
  return (
    <div className={styles.containerButton}>
      <div className={styles.container}>
      
          <label htmlFor={name}>{label}</label>
          {!required && <InputDetails text="Optional" position="top"/>}
          <input {...register(name, { required })} type={type} id={name} placeholder={placeholder} onChange={change} autoComplete="off" />
          {details && <InputDetails text={details} position="bottom"/>}
          {error && <InputError message={message}/>}
          
      </div>
      {button && button}
    </div>
  )
 } else if (hubspot) {
    return (
      <div className={styles.container}>
          
          <label htmlFor={name}>{label}</label>
          {!required && <InputDetails text="Optional" position="top"/>}
          <input type={type} id={name} placeholder={placeholder} autoComplete="off" />
          {details && <InputDetails text={details} position="bottom"/>}

      </div>
    )
 } else {
  return (
    <div className={styles.container}>
     
        <label htmlFor={name}>{label}</label>
        {!required && <InputDetails text="Optional" position="top"/>}
        <input {...register(name, { required })} type={type} id={name} placeholder={placeholder} onChange={change} autoComplete="off" />
        {isSafari & type==='date' ? <div className={styles.icon}><Icons icon="calendar-days" size="tiny" /></div> : null}
        {details && <InputDetails text={details} position="bottom"/>}
        {error && <InputError message={message}/>}

    </div>
  )
 }   
  
}
