import { useState, createContext, useEffect} from "react";

// Firebase
import { db } from "../firebase/firebase";
import { doc, onSnapshot } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

export const UserContext = createContext();

export default function UserProvider( {children} ) {
  const [data, setUserData] = useState({first: ''});
  const [isLoaded, setIsLoaded] = useState(false);

  const auth = getAuth();

  useEffect(() => {

  if (auth) {
    const fetchData = async () => {
      try {
        const userPath = doc(db, `users/${auth.currentUser.uid}`);
        const unsub = onSnapshot(userPath, (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            setUserData(docData);  
            setIsLoaded(true);
            
          }
        });
        return unsub;
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchData();
  }
  

  }, [auth]);

 

  return <UserContext.Provider value={{data}}>{isLoaded && children}</UserContext.Provider>;
}

