import React from 'react'
import Input from '../../../2molecules/Input/Input';
import styles from './FormMarketingEarlyAccess.module.css';

export default function FormMarketingEarlyAccess() {

    document.addEventListener('DOMContentLoaded', function() {
        const form = document.getElementById('EarlyAccessRequest');
        
        form.addEventListener('submit', function(event) {
          if (!validateForm()) {
            event.preventDefault(); // Prevent form submission if validation fails
            console.log('Form Blocked');
          }
        });
        
        function validateForm() {
          const nameInput = document.getElementById('name_first');
          const emailInput = document.getElementById('email');
          // const testInput = document.getElementsByName('test');
          
          // Basic validation example
          if (nameInput.value.trim() === '') {
            console.log('Name cannot be blank.');
            return false;
          }

          // if (testInput.value.trim() !== 'yes') {
          //   console.log('Must be a duck');
          //   return false;
          // }
          
          // Email validation using a regular expression
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(emailInput.value)) {
            console.log('Email is not valid.');
            return false;
          }
          
          return true; // Form is valid
        }
      });




    
  return (
    <form class={styles.marketingForm} id="EarlyAccessRequest">
        <Input label="First Name" type="text" name="name_first" required="true" hubspot="true"/>
        <Input label="Last Name" type="text" name="name_last" required="true"  hubspot="true"/>
        <Input label="City" type="text" name="location_city" required="true" hubspot="true"/>
        <Input label="State" type="text" name="location_state" required="true" hubspot="true"/>
        <Input label="Email" type="email" name="email" required="true" hubspot="true"/>
        {/* <div className={styles.inputRadio}>
            <p><strong>If it walks like a duck and wacks like a duck it is?</strong></p>
            <div>
              <input type="radio" id="no1" name="test" value="no1"/>
              <label htmlFor="html">Man in duck costume</label>
            </div>
            <div>
              <input type="radio" id="no2" name="test" value="no2"/>
              <label htmlFor="css">Cat after a year of duck impersonation lessons</label>
            </div>
            <div>
              <input type="radio" id="yes" name="test" value="yes"/>
              <label htmlFor="javascript">A duck</label>
            </div>
            <div>
              <input type="radio" id="no3" name="test" value="no3"/>
              <label htmlFor="javascript">A banana duckquiri</label>
            </div> 
        </div> */}
        <input type="submit"/>
    </form>
  )
}

