import React, {useState, useEffect} from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';

import Button from '../../2molecules/Button/Button';
import StartTemplate from '../../4templates/StartTemplate/StartTemplate';
import Input from '../../2molecules/Input/Input';

import "../../../firebase/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function SignInPage() {

    document.title = 'Sign In';

    const { register, handleSubmit, formState: {errors}, reset} = useForm();
    const [loading, setLoading] = useState('');
    const [errorMessage, setErrorMessage] = useState({count: 0, email: '', password: ''});

    const navigate = useNavigate();

    const onSubmit = (async (values) => {
        setLoading('loading');
        try {
          const auth = getAuth();
          const userCredential = await signInWithEmailAndPassword(
            auth,
            values.email,
            values.password
          );
          if (userCredential.user) {
            navigate("/main");
          }
          
        } 
        
        catch (error) {
          if (error.code === 'auth/user-not-found') {
            setErrorMessage({email: "Email Address Not Found", count: errorMessage.count + 1});
          } else if (error.code === 'auth/wrong-password') {
            setErrorMessage({password: "Wrong Password", count: errorMessage.count + 1});
          } else if (error.code === 'auth/too-many-requests') {
            setErrorMessage({password: "Too Many Requests Try Again Later", email: "Too Many Requests Try Again Later", count: 0});
          }else {
            console.log("Error:", error.code);
          }
        }
        setLoading('');
      })

      useEffect(() => {
        if (errorMessage.count > 4) {
          setErrorMessage({
            email: "Contact Support at joevessell@gmail.com", 
            password: "Contact Support at joevessell@gmail.com",
            count: 0});
        } else if (errors.email) {
          setErrorMessage({email: "Must have a value", count: errorMessage.count + 1});
        } else if (errors.password) {
          setErrorMessage({password: "Must have a value", count: errorMessage.count + 1});
        } 
      }, [errors.email, errors.password])
      
      

      

  return (
    

    <StartTemplate title={'Sign In'} >
    <form autoComplete={"off"}>
        <Input 
            label="Email" 
            type="email" 
            name="email" 
            register={register} 
            required 
            error={errorMessage.email}
            message={errorMessage.email}
        />

        <Input 
            label="Password" 
            type="password" 
            name="password" 
            register={register} 
            required 
            error={errorMessage.password}
            message={errorMessage.password}
        />
        

        <Button type={'primary'} click={handleSubmit(onSubmit)} state={loading}>Login</Button>
        <br/>
        <NavLink style={{textAlign: 'center'}} to="/passwordreset">Forgot Password?</NavLink>

        </form>
    </StartTemplate>

  )
}
