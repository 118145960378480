import React from 'react';
import styles from './headerdrawer.module.css';


export default function HeaderDrawer(props) {



  let position = ' ';
  switch (props.position) {
    case 'right':
      position += styles.right;
        break;
    case 'left':
      position += styles.left;
        break;
    case 'bottom':
      position += styles.bottom;
        break;
    default: position = styles.right;
} 


if (props.type==='popover') {
  return (
    <div popover={''} id={props.id} className={`${position} ${styles.popover}`} >
      <div className={`${styles.container}`}>
        {props.children}
      </div>
     </div>
  )
} else {
  return (
    <div ref={props.forwardedRef} className={`${position} ${styles.drawerContainer} ${props.open ? styles.open : ''}`}>
      {props.children}
     </div>
  )
  }
}
