import React from 'react'
import styles from './System404.module.css'
import { Link } from "react-router-dom";


export default function System404() {

  return (
    <div className={styles.container}>
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>Back to the main page or contact support.</p>
        <Link to="/main">Main page</Link>
    </div>
  )
  
}
