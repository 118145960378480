import React from 'react'
import styles from './marketingpage.module.css';

import slide1 from './images/markerting_slide-charities.png';
import slide2 from './images/markerting_slide-community.png';
import slide3 from './images/markerting_slide-discussion.png';
import slide4 from './images/markerting_slide-friends.png';
import slide5 from './images/markerting_slide-goals.png';
import slide6 from './images/markerting_slide-support.png';
import Button from '../../2molecules/Button/Button';
import Icons from '../../1atoms/Icons/Icons';


export default function MarketingMainSlides() {
  return (
    <section className={styles.containerSlides}>

        <article>
            <img src={slide5} alt="Our goals" />
            <h2>Goals</h2>
        </article>

        <article>
            <img src={slide4} alt="Our Friends" />
            <h2>Friends</h2>
        </article>

        <article>
            <img src={slide2} alt="Our Communities" />
            <h2>Community</h2>
        </article>

        <article>
            <img src={slide1} alt="Our Charities" />
            <h2>Charities</h2>
        </article>

        <article>
            <img src={slide6} alt="Support we give and receive" />
            <h2>Support</h2>
        </article>

        <article>
            <img src={slide3} alt="Discuss our goals" />
            <h2>Discussion</h2>
        </article>
       {/* <Button type="icon" size="small"><Icons icon="chevron-right"/></Button> */}
    </section>
  )
}
