import React from 'react';
import styles from './typedetails.module.css';

export default function TypeDetails(props) {

  let align = ' ';
  switch (props.align) {
    case 'center':
      align += styles.center;
      break;
    case 'left':
      align += styles.left;
      break;
    case 'right':
      align += styles.right;
      break;
    default: align = styles.left;
  }

  let style = ' ';
  switch (props.style) {
    case 'bold':
      style += styles.bold;
      break;
    default: style = '';
  }


  return (
    <p className={`${styles.details} ${align} ${style}`}>{props.text}</p>
  )
}
