import { useState, createContext, useEffect} from "react";

// Firebase
import { db } from "../firebase/firebase";
import { collection, onSnapshot } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

export const GoalsContext = createContext();

export default function UserProvider( {children, id, userid} ) {

  const [data, setGoalData] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const auth = getAuth();

  useEffect(() => {

  if (auth) {
    const fetchData = async () => {
      try {

          const goalQuery = collection(db, "goals");

          // const goalsFriendsPath = query(
          //   collectionGroup(db, 'usergoals'),
          //   where('userid', '!=', auth.currentUser.uid),
          //   where('goalid', '==', id)
          // );

          const unsubscribe = onSnapshot(goalQuery, (querySnapshot) => {

           
          // Set Goals
          const goals = [];
          if (querySnapshot.size === 0) {
            setGoalData('Empty State');
            // console.log('Empty State 1');
            setIsLoaded(true);
            
          } else {
            querySnapshot.forEach((doc) => {
            // Goal Selected to Show in Main Area by URL ID
            

              if (id && doc.id === id) {
                if (doc.exists) {
                  goals.push(doc.data()); 
                  setGoalData(goals);
                  setIsLoaded(true);
                } 
                
              // All Goals
              } else if (!id) {
                goals.push(doc.data());
                setGoalData(goals);
                setIsLoaded(true);
              }
            
            
        })}

          return unsubscribe;
        });

      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    fetchData();
  }
  

  }, [auth]);

 

  return <GoalsContext.Provider value={{data}}>{isLoaded && children}</GoalsContext.Provider>;
}

