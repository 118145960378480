// Import the functions you need from the SDKs you need

import 'firebase/auth'; // Add other Firebase services if needed
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDjEu-mJ6vQMmoHuk75B8KsEqttuqDbopA",
  authDomain: "greatgoals-78123.firebaseapp.com",
  projectId: "greatgoals-78123",
  storageBucket: "greatgoals-78123.appspot.com",
  messagingSenderId: "299352664917",
  appId: "1:299352664917:web:56f3406c61bd2258cd5ddc",
  measurementId: "G-YCJPW77WKZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();
// const analytics = getAnalytics(app);