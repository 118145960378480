import React from 'react';
import styles from './styles.module.css';

import logo from './images/logo.svg';
import icon from './images/icon.svg';

export default function Logo({type, size}) {



  return (
    <>
    <img className={styles.logo} src={logo}/>
    <img className={styles.icon} src={icon}/>
    </>
  )
}
