import React from 'react'
import styles from './tooltip.module.css'; 

export default function ToolTip(props) {

    const componentStyles = `
        .bottom::after {
            border-bottom: 3px solid var(--color-gray-50);
        }

        .top::after {
            border-top: 3px solid var(${props.color});
        }
    `;  

    let position = ' ';
    switch (props.position) {
    case 'top':
        position += `${styles.top} top`;
        break;
    case 'bottom':
        position += `${styles.bottom} bottom`;
        break;
    default: position = `${styles.bottom} bottom`;
    }

    


    return (
        <>
            <style>{componentStyles}</style>
            <div style={{backgroundColor: `var(${props.color ? props.color : '--color-gray-50'})`}} className={`${styles.tip} ${props.type==='friend' && styles.friend} ${position}`}> 
                {props.content} 
            </div>    
        </>
    )
}