import React from 'react';
import styles from './imageuser.module.css';
import test from '../../../images/image-no-profile.svg';
import ToolTip from '../ToolTIp/ToolTip';
import IconLoading from '../IconLoading/IconLoading';

export default function ImageUser(props) {

  let size = " ";
  switch (props.size) {
    case "tiny":
      size += styles.tiny;
      break;
    case "small":
      size += styles.small;
      break;
    case "medium":
      size += styles.medium;
      break;
    case "large":
      size += styles.large;
      break;
    default:
      size = styles.small;
  }

  let image = "";
  switch (props.image) {
    case undefined:
      image += test;
      break;
    default:
      image += props.image;
  }

  let color = "";
  switch (props.color) {
    case undefined:
      color += 'white';
      break;
    default:
      color += `var(${props.color})`;
  }



  
  switch (props.type) {
    case "button":
      return (
        <button onClick={props.click} className={`${styles.container} ${size}`}>
            <img style={{borderColor: color}} className={size} src={image} />
        </button>
      )
      break;
      case "popover":
      return (
        <button popovertarget={props.click} className={`${styles.container} ${size}`} popovertargetaction="toggle">
            <img style={{borderColor: color}} className={size} src={image} />
        </button>
      )
      break;
      case "friend":
        return (
          <div className={`${styles.container} ${size} ${styles.friend}`}>
            <img style={{borderColor: color}} className={size} src={image} />
            <div className={styles.tooltip}>
              <ToolTip type="friend" position='bottom' content={props.name}/>
            </div>
        </div>
        )
        break;

    case "display":
      return (
        <div className={`${styles.container} ${size}`}>
        {props.state==='loading' ? 
            <div className={styles.loadingOverlay}><IconLoading/></div>
         :
            <img style={{borderColor: color}} className={size} src={image} />
        }
        </div>
      )
      break;
    default:
      return (
        <div className={`${styles.container} ${size}`}>
            <img style={{borderColor: color}} className={size} src={image} />
        </div>
      )
  }


  
}
