import React, { lazy } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import PirvateRoute from "./routes/PirvateRoute";
import CheckRoute from "./routes/CheckRoute";

import SystemProvider from "./context/system";

import ScrollToHashElement from "./compoments/1atoms/ScrollToHashElement/ScrollToHashElement";

// Pages
import SignUpPage from "./compoments/5pages/StartSignUp/SignUpPage";
import SignInPage from "./compoments/5pages/StartSignIn/SignInPage";
import PasswordResetPage from "./compoments/5pages/StartPasswordReset/PasswordResetPage";

// Marketing
import MarketingMainPage from "./compoments/5pages/MarketingMain/MarketingMainPage";

// System
import System404 from "./compoments/5pages/System404/System404";

// Main
// import MainGoals from './compoments/5pages/MainGoals/MainGoals';
const MainGoals = lazy(() => import("./compoments/5pages/MainGoals/MainGoals"));

// Create
const StepsGoalCreate = lazy(() =>
  import("./compoments/5pages/StepsGoalCreate/StepsGoalCreate")
);
const StepsGoalJoin = lazy(() =>
  import("./compoments/5pages/StepsGoalJoin/StepsGoalJoin")
);

// User Profile
const ProfileCompletedGoals = lazy(() =>
  import("./compoments/5pages/ProfileCompletedGoals/ProfileCompletedGoals")
);
const ProfileBar = lazy(() =>
  import("./compoments/5pages/ProfileBar/ProfileBar")
);
const ProfileUser = lazy(() =>
  import("./compoments/5pages/ProfileUser/ProfileUser")
);

//Admin
const AdminUsers = lazy(() =>
  import("./compoments/5pages/AdminUsers/AdminUsers")
);
const AdminUsersComm = lazy(() =>
  import("./compoments/5pages/AdminUsers/AdminUsersComm")
);

const AdminAddUser = lazy(() =>
  import("./compoments/5pages/AdminUsers/AdminAddUser")
);

const AdminGoals = lazy(() =>
  import("./compoments/5pages/AdminGoals/AdminGoals")
);
const AdminAccess = lazy(() =>
  import("./compoments/5pages/AdminAccess/AdminAccess")
);

//Dev
const DevDate = lazy(() => import("./dev/DevDate"));

function App() {
  return (
    <>
      <Router>
        <ScrollToHashElement />
        <Routes>
          <Route
            path="/signup"
            element={
              <SystemProvider>
                <SignUpPage status="earlyopen" />
              </SystemProvider>
            }
          />
          <Route path="/tester" element={<SignUpPage />} />
          <Route path="/passwordreset" element={<PasswordResetPage />} />

          <Route path="/signin" element={<CheckRoute />}>
            <Route path="/signin" element={<SignInPage />} />
          </Route>

          <Route path="/" element={<CheckRoute />}>
            <Route path="/" element={<MarketingMainPage />} />
          </Route>

          <Route path="/marketing" element={<MarketingMainPage />} />

          <Route path="/main" element={<PirvateRoute />}>
            <Route path="/main" element={<MainGoals />} />
          </Route>

          {/* Settings */}
          <Route path="/profile" element={<PirvateRoute />}>
            <Route path="/profile" element={<ProfileUser />} />
          </Route>

          <Route path="/bar" element={<PirvateRoute />}>
            <Route path="/bar" element={<ProfileBar />} />
          </Route>

          <Route path="/completedgoals" element={<PirvateRoute />}>
            <Route path="/completedgoals" element={<ProfileCompletedGoals />} />
          </Route>

          <Route path="/create" element={<PirvateRoute />}>
            <Route path="/create" element={<StepsGoalCreate />} />
          </Route>

          <Route path="/join/:id/" element={<PirvateRoute />}>
            <Route path="/join/:id/" element={<StepsGoalJoin />} />
          </Route>

          <Route path="/active/:id/" element={<PirvateRoute />}>
            <Route path="/active/:id/" element={<MainGoals />} />
          </Route>

          {/* Admin */}
          <Route path="/admin/users" element={<PirvateRoute />}>
            <Route path="/admin/users" element={<AdminUsers />} />
          </Route>

          <Route path="/admin/users/manage" element={<PirvateRoute />}>
            <Route path="/admin/users/manage" element={<AdminUsers />} />
          </Route>

          <Route path="/admin/users/communication" element={<PirvateRoute />}>
            <Route
              path="/admin/users/communication"
              element={<AdminUsersComm />}
            />
          </Route>

          <Route path="/admin/users/add-user" element={<PirvateRoute />}>
            <Route path="/admin/users/add-user" element={<AdminAddUser />} />
          </Route>

          <Route path="/admin/goals" element={<PirvateRoute />}>
            <Route path="/admin/goals" element={<AdminGoals />} />
          </Route>

          <Route path="/admin/goals/:id/" element={<PirvateRoute />}>
            <Route path="/admin/goals/:id/" element={<AdminGoals />} />
          </Route>

          <Route path="/admin/access" element={<PirvateRoute />}>
            <Route path="/admin/access" element={<AdminAccess />} />
          </Route>

          <Route path="*" element={<System404 />} />
          <Route path="/404" element={<System404 />} />

          {/* Development */}
          <Route path="/dev/date" element={<DevDate />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

{
  /* <Router>
        <Routes>
          <Route path="/signup" element={<SignUpPage/>} />
          <Route path="/signin" element={<SignInPage/>} />

          <Route path='/' element={<PirvateRoute/>}>
            <Route path="/" element={<MainGoals/>}/>  
            <Route path="/main" element={<MainGoals/>}/>  
          </Route>

          <Route path='/profile' element={<PirvateRoute/>}>
            <Route path="/profile" element={<ProfileUser/>}/>  
          </Route>

          <Route path='/bar' element={<PirvateRoute/>}>
            <Route path="/bar" element={<ProfileBar/>}/>  
          </Route>

          <Route path='/create' element={<PirvateRoute/>}>
            <Route path="/create" element={<StepsGoalCreate/>}/>  
          </Route>

          <Route path='/join/:id/' element={<PirvateRoute/>}>
            <Route path="/join/:id/" element={<StepsGoalJoin/>}/> 
          </Route>

          <Route path='/active/:id/' element={<PirvateRoute/>}>
            <Route path="/active/:id/" element={<MainGoals/>}/> 
          </Route>
 
        </Routes>
      </Router> */
}
